/* eslint-disable no-param-reassign */
/* eslint-env browser */
import ready from '../components/ready';
import {
  setResponsiveNav,
  setResponsiveToggle, setCopyToClipboard,
} from '../components/nav';
import copyHexCode from '../components/copy-hex-code';
import { isElementWithClassNameInEventPath, setMetaThemeColor, setHorizontalScroll } from '../components/helpers';
import { setResponsiveTable } from '../components/table';
import { setTabs } from '../components/tabs';
import { setContentToggle } from '../components/content-toggle';
import {
  closeAllContentTogglesInToolbar,
  contentToggleEdgeHandling,
  findToolbar,
  lockBodyScroll,
  unlockBodyScroll,
} from '../components/toolbar';
import { horizontalScrollButtonNext, horizontalScrollButtonPrevious } from '../components/horizontal-scroll-btn';
import { setupAutoExpand } from '../components/text-area';
import { charCounter } from '../components/char-counter';
import { dropdownIconToggle } from '../components/form';
import { stepperLabelsCenter } from '../components/stepper';
import { showModal, hideModal } from '../components/modal-view';

let windowInnerWidth = 0;

ready(() => {
  const tabbedContent = document.querySelectorAll('.tabbed-content');
  const tabbedObjects: ITabbedObject[] = [];
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs(tabbedObjects);
  setMetaThemeColor();

  const navElements = document.querySelectorAll('.nav:not(.nav--vertical)');
  navElements.forEach((n) => setResponsiveNav(navElements, n));
  setResponsiveToggle(navElements);

  // Textarea input
  const textarea = document.getElementById("autoexpand") as HTMLTextAreaElement | null;
  setupAutoExpand(textarea);
  window.onload = function () {
    setupAutoExpand(textarea);
  };

  // Dropdown box icon toggle
  const dropDownElements: NodeListOf<HTMLElement> = document.querySelectorAll('.form__field select, .form__field__combo-box__field');
  dropDownElements.forEach((element) => {
    dropdownIconToggle(element);
  });

  // Find alle copy-to-clipboard elementer
  const ctc = document.querySelectorAll('.copy-to-clipboard');
  ctc.forEach((element) => setCopyToClipboard(element as HTMLElement, document.querySelector('html')?.getAttribute('lang') === 'en' ? 'Hexcode copied!' : 'Hexkode kopieret!'));
  copyHexCode();

  //const toggles = document.querySelectorAll('.box .content-toggle__content, .profile-header .content-toggle__content, .design-documentation .content-toggle__content');
  const toggles = document.querySelectorAll('.content-toggle__content:not(.toolbar .content-toggle__content)');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  // Hortizontal scroll buttons
  const scrollButtonsNext = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-next');
  const scrollButtonsPrevious = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-previous');
  horizontalScrollButtonNext(scrollButtonsNext);
  horizontalScrollButtonPrevious(scrollButtonsPrevious);

  const horizontalScroll = document.querySelectorAll('.horizontal-scroll');
  horizontalScroll.forEach((hs) => {
    setHorizontalScroll(hs as HTMLElement);
    window.addEventListener('resize', () => {
      setHorizontalScroll(hs as HTMLElement);
    });
  });

  const switchSegments = document.querySelectorAll('.switch__segment');
  switchSegments.forEach((switchSegment) => {
    switchSegment.addEventListener('click', () => {
      const horizontalScrollTabbed = document.querySelectorAll('.horizontal-scroll');
      horizontalScrollTabbed.forEach((hsTabbed) => {
        setHorizontalScroll(hsTabbed as HTMLElement);
      });
    });
  });

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      navElements.forEach((n) => setResponsiveNav(navElements, n));
      setTabs(tabbedObjects);
    }
  });

  // Omnibus header mobile scroll
  /*   const topBar = document.querySelector('.omnibus-header__mobile-scroll'); 
    window.addEventListener('scroll', function() {
      if (topBar) {
          if (window.scrollY > 70) {
              topBar.classList.add('omnibus-header__mobile-scroll--show'); 
              topBar.classList.remove('omnibus-header__mobile-scroll--hide');
          } else {
              topBar.classList.remove('omnibus-header__mobile-scroll--show'); 
              topBar.classList.add('omnibus-header__mobile-scroll--hide'); 
          }
      }
  }); */

  // Modal view for Omnibus 
  const modalsBtns = document.querySelectorAll('[data-modal]');
  modalsBtns.forEach((button) => {
    const modalId = button.getAttribute('data-modal') as string;
    const modal = document.getElementById(modalId);
    const closeBtn = modal?.querySelector('.modal-view__close');
    closeBtn?.addEventListener('click', () => {
      hideModal(modalId, button);
    });
    button.addEventListener('click', () => {
      showModal(modalId, button);
    });
  });

  // Search for Omnibus 
const searchInputs = document.querySelectorAll<HTMLFormElement>('.nav__search');
searchInputs.forEach((form) => {
  form.addEventListener('submit', (event) => {
    event.preventDefault();
    const input = form.querySelector('input[type="search"]');
    if (input) {
      const inputValue = (input as HTMLInputElement).value;
      const lang = document.documentElement.lang;
      let redirectURL;

      if (lang === 'da') {
        redirectURL = `./soegning#?cludoquery=${encodeURIComponent(inputValue)}&cludoDomainName=https://omnibus.au.dk/&cludopage=1&cludorefurl=https%3A%2F%2Fomnibus.au.dk%2F&cludorefpt=Omnibus%20-%20Universitetsavisen%20på%20Aarhus%20Universitet&cludoinputtype=standard`;
      }
      if (lang === 'en') {
        redirectURL = `./en/search#?cludoquery=${encodeURIComponent(inputValue)}&cludopage=1&cludorefurl=https%3A%2F%2Fomnibus.au.dk%2F&cludorefpt=Omnibus%20-%20Universitetsavisen%20på%20Aarhus%20Universitet&cludoinputtype=standard`;
      }
      window.location.href = redirectURL;
    }
  });
});

  // Logo language switch for Omnibus
  const logo = document.querySelector('.omnibus-header__logo-titel');
  const htmlLang = document.querySelector('html')?.getAttribute('lang');
  if (logo) {
    if (htmlLang === 'da') {
      logo.setAttribute('src', '/fileadmin/logos/Omnibus-logo.png');

    } else {
      logo.setAttribute('src', '/fileadmin/logos/Omnibus-logo-en.png');
    }
  }
});

//Ensures all news stories in the grid has a 3:2 aspect ratio
function updateImageSize(imageContainer, img) {
  const containerWidth = imageContainer.offsetWidth;
  const containerHeight = containerWidth * (2 / 3); 
  img.style.width = containerWidth + 'px';
  img.style.height = containerHeight + 'px';
  img.style.objectFit = 'cover'; 
}

function updateImageSizes() {
  const containers = document.querySelectorAll('.news-story__normal');
  
  containers.forEach(container => {
      const imageContainer = container.querySelector('.news-story__image-container');
      const img = imageContainer?.querySelector('img.news-story__image');

      if (img instanceof HTMLImageElement) {
          updateImageSize(imageContainer, img);
      }
  });
}

window.addEventListener('resize', updateImageSizes);
document.addEventListener('DOMContentLoaded', updateImageSizes);
