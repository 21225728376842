/* eslint-disable import/prefer-default-export */
const setInlineSelectLabel = (formField: Element): void => {
  const select = formField.querySelector('select');
  const label = formField.querySelector(`label[for="${select?.getAttribute('id')}"]`);
  if (label) {
    const labelWidth = getComputedStyle(label).width;
    select?.style.setProperty('--label-width', labelWidth);
  }
};

function dropdownIconToggle(element: HTMLElement): void { 
    let dropdownInactive = true;
    element.addEventListener('click', function (event) {
      if (dropdownInactive) {
        element.classList.add('dropdown-icon--active');
        element.classList.remove('dropdown-icon--inactive');
        dropdownInactive = false;
      } else {
        element.classList.add('dropdown-icon--inactive');
        element.classList.remove('dropdown-icon--active');
        dropdownInactive = true;
      }
    
      const handleOutsideClick = (event) => {
        if (!element.contains(event.target)) {
          element.classList.add('dropdown-icon--inactive');
          element.classList.remove('dropdown-icon--active');
          document.removeEventListener('click', handleOutsideClick);
          dropdownInactive = true;
        }
      };
    
      document.addEventListener('click', handleOutsideClick);
    });
  }



export {
  setInlineSelectLabel,
  dropdownIconToggle
};
