/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-env browser */
import 'wicg-inert';

type HTMLElementWithInert = {
  inert: boolean,
} & HTMLElement;

const hideModal = (modal: string, sender: Element, callback = () => { }): void => {
  const modalView = document.getElementById(modal) as HTMLElementWithInert;
  if (modalView) {
    modalView.classList.remove('modal-view--visible');
    modalView.inert = true;
    const page = document.querySelector('.page') as HTMLElementWithInert;
    if (page) {
      page.classList.remove('page--blur');
      page.inert = false;
    }
    document.querySelector('body')?.classList.remove('body--no-scroll');
    (sender as HTMLElement).focus();
    sender.setAttribute('aria-expanded', 'false');
    callback();
  }
};

const showModal = (
  modal: string, sender: Element, hideCallback = (): void => { }, closeable = true,
): ExtendedCleanUpPair[] => {
  const modalView = document.getElementById(modal) as HTMLElementWithInert;
  const cleanUp: ExtendedCleanUpPair[] = [];
  if (modalView) {
    modalView.classList.add('modal-view--visible');
    modalView.inert = false;
    const page = document.querySelector('.page') as HTMLElementWithInert;
    if (page) {
      page.classList.add('page--blur');
      page.inert = true;
    }
    document.querySelector('body')?.classList.add('body--no-scroll');
    sender.setAttribute('aria-expanded', 'true');

    modalView.focus();

    const clickEvent = (event: Event) => {
      if (event.target === modalView) {
        hideModal(modal, sender, hideCallback);
      }
    };

    const keyEvent = (event: Event) => {
      if ((event as KeyboardEvent).key === 'Escape') {
        hideModal(modal, sender, hideCallback);
      }
    };

    if (closeable) {
      modalView.addEventListener('click', clickEvent);
      window.addEventListener('keyup', keyEvent);

      cleanUp.push({
        element: modalView,
        clickEvent,
      });

      cleanUp.push({
        element: window,
        keyUpEvent: keyEvent,
      });
    }
  }
  return cleanUp;
};

export {
  hideModal,
  showModal,
};
